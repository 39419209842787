<template>
  <el-dialog
    ref="dialog"
    :title="editId ? '编辑平台信息' : '新增平台'"
    :model-value="dialogVisible"
    :before-close="handleClose"
    width="40%"
    :close-on-click-modal="false"
  >
    <el-form :rules="rules" ref="form" :model="appForm" label-width="120px">
      <el-form-item label="平台名称" prop="name">
        <el-input type="text" v-model="appForm.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="平台logo" prop="logo_img_id">
        <upload-imgs ref="logo_img_id" :value="initImgs" :rules="imgRules" :maxNum="1" />
      </el-form-item>
      <el-form-item label="平台id" prop="app_id">
        <el-input type="text" v-model="appForm.app_id" clearable></el-input>
      </el-form-item>
      <el-form-item label="平台站点名称" prop="title">
        <el-input type="text" v-model="appForm.title" clearable></el-input>
      </el-form-item>
      <el-form-item label="客服热线" prop="hot_line">
        <el-input type="text" v-model="appForm.hot_line" clearable></el-input>
      </el-form-item>
      <el-form-item label="资源标识" prop="tag">
        <el-input type="text" v-model="appForm.tag" clearable></el-input>
      </el-form-item>
      <el-form-item label="授权扩展类名" prop="class_name">
        <el-input type="text" v-model="appForm.class_name" clearable></el-input>
        <div class="tips">非开发人员勿更改，否则会导致相关应用无法正常授权</div>
      </el-form-item>
      <!-- <el-form-item label="登录类型" prop="login_type">
        <el-select v-model="appForm.login_type" placeholder="请选择">
                      <el-option label="授权" :value="1"></el-option>
                      <el-option label="验证码" :value="2"></el-option>
                  </el-select>
      </el-form-item> -->
      <el-form-item label="备注" prop="remark">
        <el-input type="text" v-model="appForm.remark" clearable></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleConfirm" :disabled="loading">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, reactive, getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus/lib/components'
import util from '@/lin/util/util'
import appModel from '@/model/application'
import UploadImgs from '@/component/base/upload-image'

export default {
  components: {
    UploadImgs,
  },
  emits: ['confirm'],
  setup() {
    const dialogVisible = ref(false)
    const form = ref(null)
    const appForm = ref({})
    const initImgs = ref([])
    const editId = ref()
    const loading = ref(false)
    const imgRules = reactive({
      maxSize: 2,
    })
    const ctx = getCurrentInstance()

    const init = item => {
      if (item) {
        editId.value = item.id
        appForm.value = item
        initImgs.value = [
          {
            id: item.logo_img_id,
            display: item.logo_img?.url,
            src: item.logo_img?.url,
            imgId: item.logo_img_id,
          },
        ]
      } else {
        editId.value = null
        initImgs.value = []
        appForm.value = {}
      }
      dialogVisible.value = true
    }

    // 确认修改、新增
    const handleConfirm = () => {
      form.value.validate(async valid => {
        console.log(appForm.value)
        if (valid) {
          let res
          const submitForm = {
            logo_img_id: appForm.value.logo_img_id,
            name: appForm.value.name,
            login_type: 1,
            remark: appForm.value.remark,
            tag: appForm.value.tag,
            class_name: appForm.value.class_name,
            app_id: appForm.value.app_id,
            hot_line: appForm.value.hot_line,
            title: appForm.value.title,
          }
          try {
            loading.value = true
            if (!editId.value) res = await appModel.addApplication(submitForm)
            else res = await appModel.editApplication(editId.value, submitForm)
            ElMessage.success(res.message)
            ctx.emit('confirm')
            handleClose()
          } finally {
            loading.value = false
          }
        }
      })
    }

    // 关闭弹窗
    const handleClose = done => {
      form.value.resetFields()
      dialogVisible.value = false
      if (typeof done === 'function') done()
    }

    // 定义验证规则
    const getRules = () => {
      const checkInfo = (rule, value, callback) => {
        if (!value || value.length == 0) {
          callback(new Error('请将信息补充完整'))
        } else {
          switch (rule.field) {
            case 'name':
              if (!util.checkLength(value, 2)) callback(new Error('至少输入2个字符'))
              break
            case 'app_id':
              if (!util.checkLength(value, 6)) callback(new Error('至少输入6个字符'))
              break
            default:
              callback()
              break
          }
        }
        callback()
      }
      const checkImg = async (rule, value, callback) => {
        console.log(rule, ctx.refs[rule.field].getValue())
        // 获取图片数据
        const val = await ctx.refs[rule.field].getValue()
        if (!val || val.length == 0) {
          callback(new Error('请将信息补充完整'))
        }
        appForm.value[rule.field] = val[0].imgId
        callback()
      }
      const rules = {
        name: [{ validator: checkInfo, trigger: 'blur', required: true }],
        tag: [{ validator: checkInfo, trigger: 'blur', required: true }],
        app_id: [{ validator: checkInfo, trigger: 'blur', required: true }],
        class_name: [{ validator: checkInfo, trigger: 'blur', required: true }],
        // login_type: [{ validator: checkInfo, trigger: 'blur', required: true }],
        logo_img_id: [{ validator: checkImg, trigger: 'blur', required: true }],
        hot_line: [{ validator: checkInfo, trigger: 'blur', required: true }],
        title: [{ validator: checkInfo, trigger: 'blur', required: true }],
      }
      return { rules }
    }

    const { rules } = getRules()

    return {
      rules,
      form,
      appForm,
      initImgs,
      editId,
      imgRules,
      loading,
      dialogVisible,
      handleConfirm,
      handleClose,
      getRules,
      init,
    }
  },
}
</script>

<style lang="scss" scoped>
.tips {
  color: #f56c6c;
  margin-top: 10px;
}
</style>
